<template>
  <div id="box">
    <div class="heas">
      <div class="wps">
        <div class="biaoti" @click="homepay" style="">
          <div class="boxw">
            <img src="@/assets/images/biaoti.png" width="100%" />
          </div>
        </div>
<!--        <div style="overflow: hidden; position: absolute; right: 200px">-->
<!--          <img src="@/assets/images/tu.png" alt="" />-->
<!--        </div>-->
      </div>
      <a-popover
        trigger="click"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode;
          }
        "
      >
        <div class="heduser">
          <div class="h-img">
            <a-avatar style="" :size="45" icon="user" />
            <!--            <div class="h-name">{{ nowcompany }}</div>-->
          </div>
        </div>
        <template slot="content">
          <div class="boxcont">
            <div class="b-cont">
              <a-avatar :size="45" icon="user" />
              <div class="b-list">{{ nowcompany }}</div>
              <div class="b-list">信用代码：{{ nowyscode }}</div>
              <div class="b-list">登录账号：{{ nowemail }}</div>
            </div>

            <div class="b-foot">
              <div
                  @click="modify"
                  style="color: black; font-weight: 700"
                  class="b-oper"
              >
                修改个人信息
              </div>
              <div
                @click="jump"
                style="color: black; font-weight: 700"
                class="b-oper"
              >
                订单查询
              </div>
              <div
                style="
                  color: black;
                  font-weight: 700;
                  border-bottom: 1px solid #f5f5f5;
                  display: none;
                "
                class="b-oper"
              >
                个人中心
              </div>
              <div
                @click="delLocal"
                style="color: #f39800; font-weight: 700"
                class="b-oper"
              >
                退出登录
              </div>
            </div>
          </div>
        </template>
      </a-popover>
    </div>
  </div>
</template>

<script>
import {  order_orderlist } from "@/api/manage";

export default {
  name: "NavTitle",
  components: {},
  data() {
    return {
      configdata: {},
      btnTime: null,
      datas: [],
      nowcompany: "",
      nowemail: "",
      nowyscode: "",
      timer: null,
    };
  },
  created() {
    const TIME_COUNT = 1;
    if (!this.timer) {
      this.count = TIME_COUNT;
      this.show = false;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          var companyinfo = JSON.parse(localStorage.getItem('companyinfo'))
          var configdata =  JSON.parse(localStorage.getItem('configdata'))
          if(companyinfo != null && configdata != undefined && configdata != '') {
            this.nowemail = companyinfo.phonenumber//localStorage.getItem("email");
            this.nowcompany = companyinfo.company//localStorage.getItem("company");
            this.nowyscode = companyinfo.yscode//localStorage.getItem("yscode");
          }
          this.count--;
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    }

    // console.log(this.nowcompany)

    this.http =
      window.location.hostname == "salekey.uniondept.com" ? false : true;
    this.httplogo = "/imgs/" + window.location.hostname + "/logo.png";
    this.usbkeyimg = "/imgs/" + window.location.hostname + "/key.jpg";
    let time = 5;
    this.btnTime = setInterval(() => {
      if (time == 0) {
        clearInterval(this.btnTime);
        this.timewo = 0;
        this.buttso = "我已知晓";
        // this.sssvisiblesss = false
      } else {
        this.sssvisiblesss = true;
        this.buttso = "我已知晓" + time;
        time--;
      }
    }, 1000);

    // localStorage.setItem("serviceurl", configdata.serviceurl);
  },
  mounted() {},
  methods: {
    homepay() {
      this.$router.push("/");
    },
    modify(){
      this.$router.push("/userinfo");
    },

    jump() {
      if (
        localStorage.getItem("yscode") == "" ||
        localStorage.getItem("yscode") == null ||
        localStorage.getItem("emailcode") == null ||
        localStorage.getItem("emailcode") == "" ||
        localStorage.getItem("email") == null ||
        localStorage.getItem("email") == "" ||
        localStorage.getItem("company") == null ||
        localStorage.getItem("company") == ""
      ) {
        this.$router.push("/user/login");
      } else {
        window.sessionStorage.setItem("random", 1);
        // this.Index = window.sessionStorage.getItem("random");
        // console.log(this.Index);
        this.$emit("title-click", 1);
        this.$router.push("/orderlist");
      }
    },
    delLocal() {
      this.$confirm("确定退出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("退出了");
          // removeToken()
          localStorage.removeItem("setToken");
          localStorage.removeItem("companyinfo");
          localStorage.removeItem("configdata");
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="css">
.heas {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100px;
  /*box-shadow: 5px 0px 20px 0px #e9f3ff;*/
  border-bottom: 15px solid #f5f7fb;
  background-color: #ffffff;
  /* overflow: hidden; */
  z-index: 999;
}

.wps {
  display: flex;
  height: 100px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}

.biaoti {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-family: "黑体";
  color: #333333;
}

.boxw {
  vertical-align: middle;
  margin-top:-20px;
}

.heduser {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 130px;
  height: 100px;
  /* background: #5ba656; */
  cursor: pointer;
}

.h-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
    margin-top:-15px;
}

.h-name {
  width: 70px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
}

.boxcont {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 250px;
  height: 295px;
  font-size: 12px;
  color: #333333;
}

.b-list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.b-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 170px;

  background: #f1f1f1;
  padding: 10px;
  box-sizing: border-box;
}

.b-foot {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.b-oper {
  width: 80%;
  height: 48px;
  margin: 0 auto;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
</style>
