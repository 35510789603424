var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "box" } }, [
    _c(
      "div",
      { staticClass: "heas" },
      [
        _c("div", { staticClass: "wps" }, [
          _c("div", { staticClass: "biaoti", on: { click: _vm.homepay } }, [
            _vm._m(0),
          ]),
        ]),
        _c(
          "a-popover",
          {
            attrs: {
              trigger: "click",
              getPopupContainer: (triggerNode) => {
                return triggerNode.parentNode
              },
            },
          },
          [
            _c("div", { staticClass: "heduser" }, [
              _c(
                "div",
                { staticClass: "h-img" },
                [_c("a-avatar", { attrs: { size: 45, icon: "user" } })],
                1
              ),
            ]),
            _c("template", { slot: "content" }, [
              _c("div", { staticClass: "boxcont" }, [
                _c(
                  "div",
                  { staticClass: "b-cont" },
                  [
                    _c("a-avatar", { attrs: { size: 45, icon: "user" } }),
                    _c("div", { staticClass: "b-list" }, [
                      _vm._v(_vm._s(_vm.nowcompany)),
                    ]),
                    _c("div", { staticClass: "b-list" }, [
                      _vm._v("信用代码：" + _vm._s(_vm.nowyscode)),
                    ]),
                    _c("div", { staticClass: "b-list" }, [
                      _vm._v("登录账号：" + _vm._s(_vm.nowemail)),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "b-foot" }, [
                  _c(
                    "div",
                    {
                      staticClass: "b-oper",
                      staticStyle: { color: "black", "font-weight": "700" },
                      on: { click: _vm.modify },
                    },
                    [_vm._v(" 修改个人信息 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "b-oper",
                      staticStyle: { color: "black", "font-weight": "700" },
                      on: { click: _vm.jump },
                    },
                    [_vm._v(" 订单查询 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "b-oper",
                      staticStyle: {
                        color: "black",
                        "font-weight": "700",
                        "border-bottom": "1px solid #f5f5f5",
                        display: "none",
                      },
                    },
                    [_vm._v(" 个人中心 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "b-oper",
                      staticStyle: { color: "#f39800", "font-weight": "700" },
                      on: { click: _vm.delLocal },
                    },
                    [_vm._v(" 退出登录 ")]
                  ),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "boxw" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/biaoti.png"), width: "100%" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }